import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SensorSchema } from './../../helpers/sensor-schema';
//import { MapsAPILoader } from '@hudsontavares/agm-core';
//import { Loader } from "@googlemaps/js-api-loader";
import { SpinnerService } from 'src/app/components/spinner/spinner.service';
import { global_variables } from 'src/environments/global_variables';
import { NStatus } from './status';
import { TimestampToDatePipe } from './../helpers/timestamp-to-date.pipe';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { timezones } from 'src/app/shared/timezones';
import { environment } from '../../../environments/environment';
import { Observable, Subject, first, take, takeUntil } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PurehttpService } from '../../services/purehttp.service';
import { ConfigDataService } from '../../services/config-data.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TimezoneApiService } from '../../services/timezone-api.service';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';

declare const google: any; // Declare the google object
@UntilDestroy({ checkProperties: true })
@Component({
   selector: 'app-configuration-page',
   templateUrl: './configuration-page.component.html',
   styleUrls: ['./configuration-page.component.scss'],
})
export class ConfigurationPageComponent implements OnInit {
   @Input() selectedSensor: SensorSchema;
   @Input() strUserType: string;
   @Input() strUserRole: string;
   @Input() isAdminRole: boolean;
   @Input() isStaff: boolean;
   @Input() nActionStatus: number;
   paramValue: string;
   public oneMinuteSamplingFile = '';
   public twoMinuteSamplingFile = '';
   public normalModeCalibrationFilename = {};
   public normalModeSamplingFile = {};
   public oneMinuteSearch = '';
   public twoMinuteSearch = '';
   public normalModeSearch = '';
   public fastModeCalibrationSearch = '';
   public arrConfigs: Array<object> = [];
   public filteredConfigsOne: Array<object> = [];
   public filteredConfigsTwo: Array<object> = [];
   public filteredNormalMode: Array<object> = [];
   public filteredNormalModeCal: Array<object> = [];
   public filteredFastModeCal: Array<object> = [];
   public filteredsenModel: Array<object> = [];
   public searchTerm = '';
   public nStatus = NStatus;
   public activeEdit: NStatus = NStatus.None;
   private timezones: Array<string> = timezones;
   public filteredTimeZones: Array<string> = this.timezones;
   public samplingFile: string = '';
   public samplingFileForCal = {};
   public samplingFileForCalForFastMode = {};
   public samplingFileForFastMode: string = '';
   public arrSamplingTimesFast: Array<object> = [];
   public arrSamplingTimesNormal: Array<object> = [];
   private readonly currentDataType = 'Frac_Delta';
   public arrStrUserRoles: Array<string>;
   public error: string;
   public customers: any;
   public zones: any;
   private destroy$: Subject<boolean> = new Subject();
   geocoder: any;
   fileOne: string = '';
   fileTwo: string = '';
   fileThree: string = '';
   fileFour: string = '';
   fileFive: string = '';
   senModelinput: string = '';
   sensorModels: Object[];
   enableDisblemodeButton: boolean = false;
   oldSelectedsensorObj: Object = {};
   isIntervalMode: boolean = false; // Default to Continuous mode
   selectedInterval: string = '30min';
   schedulingTypes: Array<string>;

   get currentSamplingFile(): string {
      return this.samplingFile;
   }

   set currentSamplingFile(value: string) {
      if (!this.selectedSensor['fastMode']) {
         this.samplingFile = value;
      }
   }

   get currentSamplingFileForFastMode(): string {
      return this.samplingFileForFastMode;
   }

   set currentSamplingFileForFastMode(value: string) {
      if (this.selectedSensor['fastMode']) {
         this.samplingFileForFastMode = value;
      }
   }

   // Getter for currentSamplingFile
   get currentSamplingFileBinding(): string {
      return this.selectedSensor['fastMode']
         ? this.currentSamplingFileForFastMode
         : this.currentSamplingFile;
   }

   // Setter for currentSamplingFile
   set currentSamplingFileBinding(value: string) {
      if (this.selectedSensor['fastMode']) {
         this.currentSamplingFileForFastMode = value;
      } else {
         this.currentSamplingFile = value;
      }
   }
   constructor(
      private _httpService: HttpService,
      private configDataService: ConfigDataService,
      private _snackBar: MatSnackBar,
      private _purehttpService: PurehttpService,
      private timestampToDatePipe: TimestampToDatePipe,
      private _spinner: SpinnerService, //public _mapApiLoader: MapsAPILoader
      private _timezoneAPI: TimezoneApiService,
      public dialog: MatDialog,
      private cdr: ChangeDetectorRef
   ) {
      this.paramValue = '';
      this.error = '';
      this.arrStrUserRoles = global_variables['userRoles'];
      this.arrSamplingTimesFast = [];
      this.arrSamplingTimesNormal = [];
      this.schedulingTypes = global_variables['SchedulingTypes']
   }
   ngOnInit(): void {
      this.onLoadSensorDevice();
      this.retrieveFormValues();
      this.onLoadConfig();
      this.loadGeoCoder();
      this.getSensorModels();

   }

   ngOnChanges() {
      this.onLoadSensorDevice();
   }

   onModeChange(event: any): void {
      this.isIntervalMode = event.checked;
      this.selectedInterval = this.isIntervalMode ? this.selectedInterval || '30' : null;
   
      const payload = {
         schedulingType: this.isIntervalMode ? 'interval' : 'continuous',
         schedulingInterval: this.isIntervalMode ? this.selectedInterval : null,
      };
   
      this.saveToFirebase(payload).then(() => {
         console.log('Mode updated successfully in Firebase.');
      });
   }
   


   onIntervalChange(newInterval: string): void {
      this.selectedInterval = newInterval;
   
      const payload = {
         schedulingInterval: this.selectedInterval,
      };
   
      this.saveToFirebase(payload).then(() => {
         console.log('Interval updated successfully in Firebase.');
      });
   }


   private async saveToFirebase(payload: any): Promise<void> {
      try {
         // Update Firebase data
         await this._httpService.updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, payload);

         // Update the local `selectedSensor` with the new data
         this.selectedSensor = { ...this.selectedSensor, ...payload };

         console.log('Data saved and local sensor updated:', this.selectedSensor);
      } catch (error) {
         console.error('Error saving data to Firebase:', error);
      }
   }


   onChangeSensorModel($event, isDefault: boolean = false) {
      const strSensorUrl = environment.APIS.SENSORS;
      if ($event == "null") {
         this._httpService
            .updateAsObject(`${strSensorUrl}/${this.selectedSensor.key}`, { sensorModel: '' })
            .then(
               () => {
                  this.enabledisableMode(false);
                  if (!isDefault) {
                     this._snackBar.open('Sensor Model have been set up successfully!', 'Success', {
                        duration: 1000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                     });
                  }
               },
               error => console.error(error)
            );
      } else {
         var selectedSensorModelMode = '';
         this.sensorModels.forEach(element => {
            if (element['key'] == $event) {
               selectedSensorModelMode = element['enabledModes'][0];
            }
         });
         this._httpService
            .updateAsObject(`${strSensorUrl}/${this.selectedSensor.key}`, { sensorModel: $event })
            .then(
               () => {
                  if (selectedSensorModelMode == 'fast') {
                     this.selectedSensor.fastMode = true;
                     this.onChangeDualModeBySensorModel(true);
                     this.enabledisableMode(true);
                  } else if (selectedSensorModelMode == 'normal') {
                     this.selectedSensor.fastMode = false;
                     this.onChangeDualModeBySensorModel(false);
                     this.enabledisableMode(true);
                  } else {
                     this.selectedSensor.fastMode = false;
                     this.onChangeDualModeBySensorModel(false);
                     this.enabledisableMode(false);
                  }
                  if (!isDefault) {
                     this._snackBar.open('Sensor Model have been set up successfully!', 'Success', {
                        duration: 1000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                     });
                  }
               },
               error => console.error(error)
            );
      }
   }
   getSensorModels() {
      this._httpService.getListByOrder(`${environment.APIS.SENSORMODELS}`, 'sensorTypeId', this.selectedSensor.sensorTypeId).subscribe({
         next: (sensorModels) => {
            this.sensorModels = sensorModels;
            this.filteredsenModel = sensorModels;
            this.oldSelectedsensorObj = {
               sensorModel: this.selectedSensor['sensorModel'],
               fastMode: this.selectedSensor.fastMode
            }
            this.sensorModels.forEach(element => {
               if (element['key'] == this.selectedSensor['sensorModel']) {
                  if (element['enabledModes'][0] != 'dual') {
                     this.enabledisableMode(true);
                  }
               }
            });
         },
         error: error => {
            console.error('configuration-page.component.ts -> this._httpService.getAsList: ', error);
         }
      });
   }
   onChangeDualModeBySensorModel(checked) {

      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, {
            fastMode: checked,
         })
         .then(
            () => {
               this.callFirebasefunctionandUpdateSensor();
               this._snackBar.open('Operation Mode update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );

   }

   enabledisableMode(val) {
      if (this.nActionStatus === 2 || this.nActionStatus === 12 || val === true) {
         this.enableDisblemodeButton = true;
      } else {
         this.enableDisblemodeButton = false;
      }
   }

   callFirebasefunctionandUpdateSensor() {
      const objPostData = {
         sensorId: this.selectedSensor.key
      };
      this._spinner.start();
      const strFireFunctionUrl = environment['FirebaseFunctionUrlCloud'];
      this._purehttpService.callFirebaseFunction(`${strFireFunctionUrl}/${environment.SystemType}LoadCurrentConfigData`, objPostData).subscribe({
         next: (res: any) => {
            this._spinner.stop();
            console.log(res);
            this._snackBar.open(res.message, 'Success', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            this.oldSelectedsensorObj = {
               sensorModel: this.selectedSensor['sensorModel'],
               fastMode: this.selectedSensor.fastMode
            }
            /* if (res.status === 'success') {
               this._snackBar.open('Invitation email is successfully sent', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            } else {
               this._snackBar.open('Fail to update  because of mail service', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            } */
         },
         error: error => {
            this._httpService
               .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, this.oldSelectedsensorObj);
            this._spinner.stop();
            console.error('configuration-page.component.ts -> callFirebasefunctionandUpdateSensor(): ', error);

            this._snackBar.open('Failed to update sensor model. Please assign all files.', 'Error', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
         }
      });
   }

   private onLoadSensorDevice() {
      this._httpService.getAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`).subscribe({
         next: device => {
            this.nActionStatus = device['actionStatus'];
         },
         error: error => console.error('sensor-run.component.ts -> onLoadSensorDevice(): ', error),
      });
   }
   isAutomationIsRunning(): boolean {
      return this.nActionStatus === 2;
   }

   async onUpdateConfigForCal(doc) {
      const { key, name, path, url } = doc;

      if (url) {
         const objPostData = {
            url,
         };

         await this._httpService
            .updateAsObject(`${environment.APIS.SENSORCALIBRATION}/${this.selectedSensor['key']}/current`, {
               calibrationConfigFile: doc,
            })
            .then(() => {
               console.log('Calibration file has been set up successfully!');

            })
            .catch(_ => {
               this._snackBar.open('Something wrong...', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            });
      }
   }


   onLoadConfig() {
      this.onLoadConfigFiles().subscribe({
         next: configs => {
            console.log('onLoadConfigFiles');
            let pos = 0;
            this.arrConfigs = configs
               .filter(item => item.modalType === 2) // omni-2100
               .sort((a, b) => parseFloat(b.timestamp) - parseFloat(a.timestamp))
               .map(item => {
                  pos++;
                  return {
                     position: pos,
                     name: item['path'].split('/').pop(),
                     url: item['configUrl'],
                     path: item['path'],
                     time: this.timestampToDatePipe.transform(item['timestamp']),
                     key: item['key'],
                  };
               });
            this.filteredConfigsOne = this.arrConfigs.slice();
            this.filteredConfigsTwo = this.arrConfigs.slice();
            this.filteredNormalMode = this.arrConfigs.slice();
            this.filteredNormalModeCal = this.arrConfigs.slice();
            this.filteredFastModeCal = this.arrConfigs.slice();

            if (!this.selectedSensor['maxRuns']) {
               this.selectedSensor['maxRuns'] = 999;
               this.onChangeMaxRuns(null, true);
            }

            this.selectedSensor['fastModeConfigName2'] = this.selectedSensor['fastModeConfigName2'] || 'Fast Mode Config 2';
            this.selectedSensor['fastModeConfigName1'] = this.selectedSensor['fastModeConfigName1'] || 'Fast Mode Config 1';

            if (!this.selectedSensor['fastModeConfigName1']) {
               void this.onChangeDualModeLabel(new Event('change'), 'one');
            }

            if (!this.selectedSensor['fastModeConfigName2']) {
               void this.onChangeDualModeLabel(new Event('change'), 'two');
            }

            this.selectedSensor['normalModeConfigName'] = this.selectedSensor['normalModeConfigName'] || 'Normal mode config';

            if (!this.selectedSensor['normalModeConfigName']) {
               void this.onChangeDualModeLabel(new Event('change'), 'normal');
            }

            if (this.selectedSensor['fastModeFile']) {
               this.oneMinuteSamplingFile = this.selectedSensor['fastModeFile']['key'];
               this.oneMinuteSearch = this.selectedSensor['fastModeFile']['key'];
               this.arrSamplingTimesFast[0] = this.selectedSensor['fastModeFile'];
               this.arrSamplingTimesFast[0]['name'] = this.selectedSensor['fastModeConfigName1'] || 'Fast Mode Config 1';
            }

            if (this.selectedSensor['fastModeFile2']) {
               this.twoMinuteSamplingFile = this.selectedSensor['fastModeFile2']['key'];
               this.twoMinuteSearch = this.selectedSensor['fastModeFile2']['key'];
               this.arrSamplingTimesFast[1] = this.selectedSensor['fastModeFile2'];
               this.arrSamplingTimesFast[1]['name'] = this.selectedSensor['fastModeConfigName2'] || 'Fast Mode Config 2';
            }

            console.log(this.arrSamplingTimesFast, 'this.arrSamplingTimesFast');

            if (this.selectedSensor['normalModeConfigFile']) {
               this.normalModeSamplingFile = this.selectedSensor['normalModeConfigFile']['key'];
               this.normalModeSearch = this.selectedSensor['normalModeConfigFile']['key'];
               this.arrSamplingTimesNormal[0] = this.selectedSensor['normalModeConfigFile'];
               this.arrSamplingTimesNormal[0]['name'] = this.selectedSensor['normalModeConfigName'] || 'Normal mode config';
            }

            if (this.selectedSensor['samplingFile']) {
               this.samplingFile = this.selectedSensor['samplingFile']['key'];
            }

            if (this.selectedSensor['samplingFileForFastMode']) {
               this.samplingFileForFastMode = this.selectedSensor['samplingFileForFastMode']['key'];
            }

            if (this.selectedSensor['samplingFileForCal']) {
               this.samplingFileForCal = this.selectedSensor['samplingFileForCal']['key'];
            }

            if (this.selectedSensor['samplingFileForCalForFastMode']) {
               this.samplingFileForCalForFastMode = this.selectedSensor['samplingFileForCalForFastMode']['key'];
            }

            if (this.selectedSensor['currentSamplingFile']) {
               this.currentSamplingFile = this.selectedSensor['currentSamplingFile'];
            }

            if (this.selectedSensor['currentSamplingFileForFastMode']) {
               this.currentSamplingFileForFastMode = this.selectedSensor['currentSamplingFileForFastMode'];
            }

            // Assuming `selectedSensor` contains the Firebase data
            // continous
            if (this.selectedSensor['schedulingType'] === this.schedulingTypes[0]) {
            this.isIntervalMode = false 
             } else if (this.selectedSensor['schedulingType'] === this.schedulingTypes[1]) {
               // interval
               this.isIntervalMode = true 
            } // Set mode based on the presence of an interval
            this.selectedInterval = this.selectedSensor['schedulingInterval'];
            this.cdr.detectChanges();
         },
         error: error => console.error('configuration-page.component.ts -> onLoadConfig():', error)
      });
   }


   onEdit(status: NStatus) {
      this.activeEdit = status;
   }

   loadGeoCoder() {
      /* this._mapApiLoader.load().then(() => {
         this.geocoder = new google.maps.Geocoder();
      }); */
      /* const loader = new Loader({
         apiKey: environment.googleAPIKey,
         version: "weekly",
       }); */

      //loader.importLibrary('maps').then(() => {
      google.maps.importLibrary('maps').then(() => {
         this.geocoder = new google.maps.Geocoder();
      });
   }

   getTimezone(data) {
      console.log(data);
      if (!data.lat || !data.lng) {
         return;
      }
      const address = `${data.lat},${data.lng}`;
      //this._cachedAddress = address;

      this._timezoneAPI
         .getTimezone(address)
         .pipe(first(), takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               console.log(response);
               if (response && response['timeZoneId']) {
                  console.log('response', response, this.selectedSensor.timezone);
                  if (response['timeZoneId'] != this.selectedSensor.timezone) {
                     var config = {
                        disableClose: true,
                        data: {
                           title: 'Confirmation',
                           description: `The timezone is different with the new GPS coordinates, Old one is ` + this.selectedSensor.timezone + ` and new one is ` + response['timeZoneId'] + `. Please click 'Yes' to update the new timezone.`,
                        },
                     };
                     this.dialog
                        .open(ConfirmModalComponent, config)
                        .afterClosed()
                        .pipe(take(1))
                        .subscribe({
                           next: (result) => {
                              if (result) {
                                 const strSensorUrl = environment.APIS.SENSORS;
                                 this._spinner.start();
                                 const objUpdateValue = <any>{};
                                 objUpdateValue['timezone'] = response['timeZoneId'];
                                 this.updateSensorData(strSensorUrl, objUpdateValue);
                              }
                           },
                           error: error => console.error('configuration-page.component.ts -> confirmDeleteAnalyticalData(): ', error)
                        });
                  }
               }
            },
            error: error => {
               console.error('configuration-page.component.ts -> getZoneName(): ', error);
            }
         });
   }

   onSave() {
      this.activeEdit = NStatus.Default;

      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, this.selectedSensor)
         .then(
            () => {
               this._snackBar.open('The address param updates successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => {
               this._snackBar.open('Firebase Error', 'Error', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
               console.error(error);
            }
         );
   }

   getSelectedFileName(selectedFileKey: string): string {
      const selectedFile = this.arrConfigs.find(file => file['key'] === selectedFileKey);
      return selectedFile ? selectedFile['name'] : '';
   }

   filterZones($event: string) {
      const timezones = [...this.timezones];
      this.filteredTimeZones = timezones.filter(t => t.toLowerCase().includes($event.trim().toLowerCase()));
   }

   onUpdateWeather(weather: string) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, { weatherSensor: weather })
         .then(
            () => {
               this._snackBar.open('Sensor weather update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   updateModemSensorId(modem: string) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, { 'modem/sensorId': modem })
         .then(
            () => {
               this._snackBar.open('Sensor modem update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   updateGeolocationSensor(geoloc: string) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, { chosenGeoLocationOption: geoloc })
         .then(
            () => {
               this._snackBar.open('Sensor Geolocation update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   onSearchElement(value: any, type: string) {
      if (!value.length) {
         return;
      }
      console.log('value', value);
      switch (type) {
         case 'one':
            this.filteredConfigsOne = this.arrConfigs.slice().filter(config => {
               const name = config['name'].toLowerCase().trim();

               return name.includes(value.toLowerCase().trim());
            });
            break;

         case 'two':
            this.filteredConfigsTwo = this.arrConfigs.slice().filter(config => {
               const name = config['name'].toLowerCase().trim();

               return name.includes(value.toLowerCase().trim());
            });
            break;
         case 'normal':
            this.filteredNormalMode = this.arrConfigs.slice().filter(config => {
               const name = config['name'].toLowerCase().trim();

               return name.includes(value.toLowerCase().trim());
            });
            break;
         case 'normalCal':
            this.filteredNormalModeCal = this.arrConfigs.slice().filter(config => {
               const name = config['name'].toLowerCase().trim();

               return name.includes(value.toLowerCase().trim());
            });
            break;
         case 'senModel':
            this.filteredsenModel = this.sensorModels.slice().filter(config => {
               const name = config['modelName'].toLowerCase().trim();

               return name.includes(value.toLowerCase().trim());
            });
            break;
      }
   }


   onChangeTwoMinutesSamplingFile($event: Event, isDefault: boolean = false) {
      const strSensorUrl = environment.APIS.SENSORS;
      const doc = this.arrConfigs.find(file => file['key'] === $event);

      // Ensure doc exists and then update this.arrSamplingTimesFast[1]
      if (doc) {
         this.arrSamplingTimesFast[1] = { ...doc };
         this.arrSamplingTimesFast[1]['name'] = this.selectedSensor['fastModeConfigName2'];
         const updatedObject = { ...doc, name: this.selectedSensor['fastModeConfigName2'] };
         this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor.key}`, { fastModeFile2: updatedObject }).then(
            () => {
               if (!isDefault) {
                  this._snackBar.open('Update is successful!', 'Success', {
                     duration: 1000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               }
            },
            error => console.error(error)
         );
      } else {
         console.error('No matching configuration file found for the event:', $event);
      }
   }

   onChangeOneMinuteSamplingFile($event: Event, isDefault: boolean = false) {
      const strSensorUrl = environment.APIS.SENSORS;
      const doc = this.arrConfigs.find(file => file['key'] === $event);

      if (doc) {
         // Assign name property to arrSamplingTimesFast[0]
         this.arrSamplingTimesFast[0] = { ...doc };
         this.arrSamplingTimesFast[0]['name'] = this.selectedSensor['fastModeConfigName1'];

         // Update the database including the name property
         const updatedObject = { ...doc, name: this.selectedSensor['fastModeConfigName1'] };
         this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor.key}`, { fastModeFile: updatedObject }).then(
            () => {
               if (!isDefault) {
                  this._snackBar.open('Update is successful!', 'Success', {
                     duration: 1000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               }
            },
            error => console.error(error)
         );
      } else {
         console.error('No matching configuration file found for the event:', $event);
      }
   }

   onChangeNormalModeSamplingFile($event: Event, isDefault: boolean = false) {
      const strSensorUrl = environment.APIS.SENSORS;
      const doc = this.arrConfigs.find(file => file['key'] === $event);

      if (doc) {
         // Assign name property to arrSamplingTimesNormal[0]
         this.arrSamplingTimesNormal[0] = { ...doc };
         this.arrSamplingTimesNormal[0]['name'] = this.selectedSensor['normalModeConfigName'];

         // Update the database including the name property
         const updatedObject = { ...doc, name: this.selectedSensor['normalModeConfigName'] };
         this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor.key}`, { normalModeConfigFile: updatedObject }).then(
            () => {
               if (!isDefault) {
                  this._snackBar.open('Update is successful!', 'Success', {
                     duration: 1000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               }
            },
            error => console.error(error)
         );
      } else {
         console.error('No matching configuration file found for the event:', $event);
      }
   }


   async onAssignSamplingTime(event: any, isDefault: boolean = false) {
      this.currentSamplingFile = event;
      const strSensorUrl = environment.APIS.SENSORS;
      const doc = this.arrConfigs.find(file => file['key'] === event);

      if (doc) {
         try {
            if (this.selectedSensor && this.selectedSensor['key']) {
               const updateData = this.selectedSensor.fastMode
                  ? { samplingFileForFastMode: doc, currentSamplingFileForFastMode: event }
                  : { samplingFile: doc, currentSamplingFile: event };

               await this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, updateData);

               if (!isDefault) {
                  this._snackBar.open('Sampling File has been set up successfully!', 'Success', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               }

               await this.onLoadConfigData(doc);
            }
         } catch (error) {
            console.error('Error updating sampling file:', error);
         }
      }
   }
   async onAssignSamplingTimeForCalibration($event: any, isDefault: boolean = false) {
      const strSensorUrl = environment.APIS.SENSORS;
      const doc = this.arrConfigs.find(file => file['key'] === $event);

      if (doc) {
         try {
            if (this.selectedSensor && this.selectedSensor['key']) {
               await this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, {
                  samplingFileForCal: doc,
               });
               this.samplingFileForCal = doc['key'];
            }

            // if (!isDefault) {
            //    this._snackBar.open('calibration File has been set up successfully!', 'Success', {
            //       duration: 3000,
            //       verticalPosition: 'top',
            //       horizontalPosition: 'center',
            //    });
            // }

            // await this.onUpdateConfigForCal(doc);
            // if (!isDefault) {
            //    this._snackBar.open('calibration File has been set up successfully!', 'Success', {
            //       duration: 3000,
            //       verticalPosition: 'top',
            //       horizontalPosition: 'center',
            //    });
            // }

            // await this.onUpdateConfigForCal(doc);
         } catch (error) {
            console.error('Error updating sampling file:', error);
         }
      }
   }
   async onAssignSamplingTimeForCalibrationForFast($event: any, isDefault: boolean = false) {
      const strSensorUrl = environment.APIS.SENSORS;
      const doc = this.arrConfigs.find(file => file['key'] === $event);

      if (doc) {
         try {
            if (this.selectedSensor && this.selectedSensor['key']) {
               await this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, {
                  samplingFileForCalForFastMode: doc,
               });
               this.samplingFileForCalForFastMode = doc['key'];
            }

            // this.samplingFileForCal = doc['key'];
            //  if (!isDefault) {
            //             this._snackBar.open('calibration File has been set up successfully!', 'Success', {
            //                duration: 3000,
            //                verticalPosition: 'top',
            //                horizontalPosition: 'center',
            //             });
            //          }

            //          await this.onUpdateConfigForCal(doc);

         } catch (error) {
            console.error('Error updating sampling file:', error);
         }
      }
   }

   private onLoadConfigFiles(): Observable<any> {
      return this._httpService.getListByOrder(`${environment.APIS.CONFIGURATIONS}`, 'mode', this.currentDataType, 1);
   }

   updateWeatherSensorId(weatherSensorId: string) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, { weatherSensorId: weatherSensorId })
         .then(
            () => {
               this._snackBar.open('Sensor weather update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   onChangeMaxRuns($event: Event, isDefault: boolean = false) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, {
            maxRuns: !isDefault ? +$event.currentTarget['value'] || 999 : this.selectedSensor['maxRuns'],
         })
         .then(
            () => {
               this._snackBar.open('Max Runs update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   retrieveFormValues() {
      // ... existing code to retrieve form values

      // Fetch the updated data after making changes
      this._httpService.getAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`).subscribe({
         next: (sensorData: SensorSchema) => {
            // Update the selectedSensor with the fetched data
            this.selectedSensor = sensorData;

            // Retrieve the fastModeFile name
            if (this.selectedSensor.fastModeFile) {
               this.oneMinuteSamplingFile = this.selectedSensor.fastModeFile['key'];
               console.log('this.oneMinuteSamplingFile', this.oneMinuteSamplingFile);
            }

            if (this.selectedSensor.fastModeFile2) {
               this.twoMinuteSamplingFile = this.selectedSensor.fastModeFile2['key'];
            }
            if (this.selectedSensor.normalModeConfigFile) {
               this.normalModeSamplingFile = this.selectedSensor.normalModeConfigFile['key'];
            }

            if (this.selectedSensor.samplingFile) {
               this.samplingFile = this.selectedSensor.samplingFile['key'];
            } else if (this.selectedSensor['samplingFileForFastMode']) {
               this.samplingFileForFastMode = this.selectedSensor['samplingFileForFastMode']['key'];
            }

            if (this.selectedSensor['samplingFileForCalForFastMode']) {
               this.samplingFileForCalForFastMode = this.selectedSensor['samplingFileForCalForFastMode']['key'];
            }
            if (this.selectedSensor['samplingFileForCal']) {
               this.samplingFileForCal = this.selectedSensor['samplingFileForCal']['key'];
            }
            if (this.selectedSensor['currentSamplingFile']) {
               this.currentSamplingFile = this.selectedSensor['currentSamplingFile'];
            }
            if (this.selectedSensor['currentSamplingFileForFastMode']) {
               this.currentSamplingFileForFastMode = this.selectedSensor['currentSamplingFileForFastMode'];
            }
         },
         error: error => console.error('configuration-page.component.ts -> retrieveFormValues():', error)
      });
   }



   async onLoadConfigData(doc: any): Promise<void> {
      const { key, name, path, url } = doc;

      if (url) {
         const objPostData = {
            url,
         };

         try {
            const strFireFunctionUrl = environment['FirebaseFunctionUrlCloud']; // cloud

            const res: any = await this._purehttpService
               .callFirebaseFunction(`${strFireFunctionUrl}/getData`, objPostData)
               .toPromise();

            const data = this.configDataService.fillDefaultConfigProps(res.data);

            await this._httpService.postAsObject(
               `${environment['APIS']['SENSORCONFIGS']}/${this.selectedSensor['key']}`,
               {
                  Current_Modal_Type: 2,
                  File_Name: doc['name'] ? doc['name'] : '',
                  Comment: 'Configuration file',
               }
            );

            console.log('Modal type update is successful!');

            await this._httpService.postAsObject(
               `${environment['APIS']['SENSORCONFIGS']}/${this.selectedSensor['key']}/${2}`,
               data
            );

            console.log('The sensor is configured successfully!');

            await this._httpService.updateAsObject(
               `${environment['APIS']['SENSORDEVICES']}/${this.selectedSensor['key']}`,
               {
                  File_Name: doc['name'] ? doc['name'] : '',
                  fileName: doc['name'] ? doc['name'] : '',
                  fileKey: doc['key'] ? doc['key'] : '',
               }
            );

            console.log('Assign is successful!');
         } catch (error) {
            console.error('Failed to get the file data or update configurations:', error);
         }
      } else {
         console.log('The URL is not defined');
      }
   }

   onChangeDualModeLabel($event: Event, label: string) {
      let key: string | undefined;

      switch (label) {
         case 'one':
            key = 'fastModeConfigName1';
            break;
         case 'two':
            key = 'fastModeConfigName2';
            break;
         case 'normal':
            key = 'normalModeConfigName';
            break;
         // No default case, key remains undefined for unmatched labels
      }
      if (key === undefined) {
         // Handle the case when label doesn't match any specific value
         console.error(`Unexpected label: ${label}`);
         return;
      }

      if (!$event) {
         return;
      }
      const strSensorUrl = environment.APIS.SENSORS;


      return this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, { [key]: $event }).then(
         () => {

            this.arrSamplingTimesFast[0]['name'] = this.selectedSensor['fastModeConfigName1'];
            this.arrSamplingTimesFast[1]['name'] = this.selectedSensor['fastModeConfigName2'];
            this.arrSamplingTimesNormal[0]['name'] = this.selectedSensor['normalModeConfigName'];

         },
         error => console.error(error)
      );
   }

   updateCleaningCycle(cleaningCycle: string) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, {
            cleaningCycle: cleaningCycle,
            totalCyclesCount: 1,
         })
         .then(
            () => {
               this._snackBar.open('Cleaning cycle update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }

   onUpdate() {
      const strSensorUrl = environment.APIS.SENSORS;

      this._spinner.start();
      const objUpdateValue = <any>{};

      this.activeEdit = NStatus.Address;

      if (!this.selectedSensor.address) {
         this._snackBar
            .open('Address is required.', 'Error', {
               duration: 2000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            })
            .afterDismissed()
            .subscribe({
               next: () => {
                  this._spinner.stop();
               },
               error: error => console.error('configuration-page.component.ts -> onUpdate():', error),
            });
         return;
      }

      this.getLatitudeLongitude((result, strAddress) => {
         let lat = result.geometry.location.lat();
         let lng = result.geometry.location.lng();
         lat = lat.toFixed(6);
         lng = lng.toFixed(6);

         objUpdateValue['lat'] = lat;
         objUpdateValue['lng'] = lng;
         objUpdateValue['address'] = strAddress;

         const address = `${lat},${lng}`;

         this._timezoneAPI
            .getTimezone(address)
            .pipe(first(), takeUntil(this.destroy$))
            .subscribe({
               next: (response) => {
                  if (response && response['timeZoneId']) {
                     objUpdateValue['timezone'] = response['timeZoneId'];
                     this.updateSensorData(strSensorUrl, objUpdateValue);
                  }
               },
               error: error => {
                  console.error('create.sensor.component.ts -> getZoneName(): ', error);
               }
            });

         this.updateSensorData(strSensorUrl, objUpdateValue);
         this.activeEdit = NStatus.Default;
      }, this.selectedSensor.address);
   }

   getLatitudeLongitude(callback, strAddress: string) {
      if (strAddress && strAddress !== '') {
         // If address is not supplied, use default value 'Ferrol, Galicia, Spain'
         strAddress = strAddress || 'Ferrol, Galicia, Spain';
         // Initialize the Geocoder
         const geocoder = new google.maps.Geocoder();
         geocoder.geocode(
            {
               address: strAddress,
            },
            (results, status) => {
               if (status === google.maps.GeocoderStatus.OK) {
                  callback(results[0], strAddress);
               }
            }
         );
      } else {
         console.log('Address is required.');
      }
   }

   updateSensorData(strSensorUrl: string, objUpdateValue: any) {
      this._httpService.updateAsObject(`${strSensorUrl}/${this.selectedSensor['key']}`, objUpdateValue).then(
         () => {
            this._spinner.stop();
            this.clearEdit();
            const snackBarRef = this._snackBar.open('The sensor GEO location updated successfully!', 'Success', {
               duration: 2000, // Adjust the duration to your desired value (less than 30 seconds)
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            setTimeout(() => {
               snackBarRef.dismiss();
            }, 2000); // Close the snack bar after 30 seconds (adjust the time accordingly)
         },
         error => {
            this._spinner.stop();
            this._snackBar.open('Firebase Error!', 'Error', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            console.error(error);
         }
      );
   }

   showResult(result: any, strAddress: string, that: any) {
      let lat = result.geometry.location.lat();
      let lng = result.geometry.location.lng();
      lat = lat.toFixed(6);
      lng = lng.toFixed(6);

      // update latitude
      const objUpdateValue: Object = {
         lat: lat,
         lng: lng,
         address: strAddress,
      };

      console.log(objUpdateValue, ' update the lat/lng and address');
      // update the lat/lng and address
      that.updateSensorData(environment.APIS.SENSORS, objUpdateValue);
   }

   clearEdit() {
      this.paramValue = '';
      this.error = '';
   }

   async onChangeDualMode($event: MatSlideToggleChange) {

      try {
         const {
            source: { checked },
         } = $event;

         this._httpService
            .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, {
               fastMode: checked,
            })
            .then(
               () => {
                  this._snackBar.open('Operation Mode update is successful!', 'Success', {
                     duration: 3000,
                     verticalPosition: 'top',
                     horizontalPosition: 'center',
                  });
               },
               error => console.error(error)
            );
         this.selectedSensor['fastMode'] = $event.checked;

         // Automatically trigger ngModelChange with the current file value
         // Automatically trigger ngModelChange with the current file value
         const fileKey = this.selectedSensor['fastMode']
            ? this.selectedSensor['samplingFileForFastMode']['key']
            : this.selectedSensor['samplingFile']['key'];
         console.log(this.selectedSensor['fastMode'], fileKey, '8888888');
         const doc = this.arrConfigs.find(file => file['key'] === fileKey);
         this.currentSamplingFile = doc['key'];
         await this.onLoadConfigData(doc);
      } catch (err) {
         console.error(err)
      }
   }

   onChangeSamplingTime($event: Event) {
      this._httpService
         .updateAsObject(`${environment.APIS.SENSORS}/${this.selectedSensor.key}`, {
            fastModeSamplingTime: +$event.currentTarget['value'] || 30,
         })
         .then(
            () => {
               this._snackBar.open('Sampling Time update is successful!', 'Success', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
            },
            error => console.error(error)
         );
   }
}
