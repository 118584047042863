export interface SensorModel {
    modelName: string;
    enabledModes: string[];
    gasList: string[];
    sensorTypeId: string;
    schedulingType: Scheduling.Continuous | Scheduling.Interval; // Allow both types
    schedulingInterval: Scheduling.OneHour | Scheduling.ThirtyMinutes | null; // Allow null for unset intervals
}


export enum Scheduling {
    // Scheduling Types
    Continuous = 'continuous',
    Interval = 'interval',

    // Scheduling Intervals
    OneHour = '1hr',
    ThirtyMinutes = '30min',
}
