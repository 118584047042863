<div class="configuration-page">
   <div class="card-left">
      <mat-card appearance="outlined">
         <mat-card-content class="content container-fluid">
            <h5>Location and Contacts</h5>
            <table class="table table-bordered table-striped">
               <thead>
                  <tr>
                     <th>Parameter</th>
                     <th>Value</th>
                     <th>Action</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngIf="isStaff">
                     <td>Name</td>
                     <td>
                        <ng-container *ngIf="activeEdit === nStatus.Name">
                           <mat-form-field>
                              <input matInput type="text" [(ngModel)]="selectedSensor.name" />
                           </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="activeEdit !== nStatus.Name">{{ selectedSensor.name }}</ng-container>
                     </td>
                     <td>
                        <div fxLayout fxLayoutAlign="center">
                           <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                              <mat-icon *ngIf="activeEdit !== nStatus.Name"
                                 (click)="onEdit(nStatus.Name)">edit</mat-icon>
                           </div>
                           <ng-container *ngIf="activeEdit === nStatus.Name">
                              <mat-icon class="pointer mr-1" color="primary" (click)="onSave()">check </mat-icon>

                              <div class="delete-item pointer" fxLayout fxLayoutAlign="center center">
                                 <mat-icon (click)="onEdit(nStatus.Default)">close </mat-icon>
                              </div>
                           </ng-container>
                        </div>
                     </td>
                  </tr>
                  <tr *ngIf="isStaff">
                     <td>Customer Name</td>
                     <td>
                        <ng-container *ngIf="activeEdit === nStatus.Name">
                           <mat-form-field>
                              <input matInput type="text" [(ngModel)]="selectedSensor['customerName']" />
                           </mat-form-field>
                        </ng-container>
                        <ng-container
                           *ngIf="activeEdit !== nStatus.Name">{{ selectedSensor.customerName }}</ng-container>
                     </td>
                     <td>
                        <div fxLayout fxLayoutAlign="center">
                           <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                              <mat-icon *ngIf="activeEdit !== nStatus.Name"
                                 (click)="onEdit(nStatus.Name)">edit</mat-icon>
                           </div>
                           <ng-container *ngIf="activeEdit === nStatus.Name">
                              <mat-icon class="pointer mr-1" color="primary" (click)="onSave()">check </mat-icon>

                              <div class="delete-item pointer" fxLayout fxLayoutAlign="center center">
                                 <mat-icon (click)="onEdit(nStatus.Default)">close </mat-icon>
                              </div>
                           </ng-container>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td>Latitude</td>
                     <td>
                        <ng-container *ngIf="activeEdit === nStatus.Latitude">
                           <mat-form-field>
                              <input matInput type="text" [(ngModel)]="selectedSensor.lat" />
                           </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="activeEdit !== nStatus.Latitude">{{ selectedSensor.lat }}</ng-container>
                     </td>
                     <td>
                        <div fxLayout fxLayoutAlign="center">
                           <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                              <mat-icon *ngIf="activeEdit !== nStatus.Latitude"
                                 (click)="onEdit(nStatus.Latitude)">edit</mat-icon>
                           </div>
                           <ng-container *ngIf="activeEdit === nStatus.Latitude">
                              <mat-icon class="pointer mr-1" color="primary" (click)="onSave()">check </mat-icon>

                              <div class="delete-item pointer" fxLayout fxLayoutAlign="center center">
                                 <mat-icon (click)="onEdit(nStatus.Default)">close </mat-icon>
                              </div>
                           </ng-container>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td>Longitude</td>
                     <td>
                        <ng-container *ngIf="activeEdit === nStatus.Longitude">
                           <mat-form-field>
                              <input matInput type="text" [(ngModel)]="selectedSensor.lng" />
                           </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="activeEdit !== nStatus.Longitude">{{ selectedSensor.lng }}</ng-container>
                     </td>
                     <td>
                        <div fxLayout fxLayoutAlign="center">
                           <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                              <mat-icon *ngIf="activeEdit !== nStatus.Longitude"
                                 (click)="onEdit(nStatus.Longitude)">edit</mat-icon>
                           </div>
                           <ng-container *ngIf="activeEdit === nStatus.Longitude">
                              <mat-icon (click)="onSave()" class="pointer mr-1" color="primary">check </mat-icon>

                              <div class="delete-item pointer" fxLayout fxLayoutAlign="center center">
                                 <mat-icon (click)="onEdit(nStatus.Default)">close</mat-icon>
                              </div>
                           </ng-container>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td>Time zone</td>
                     <td>
                        <ng-container *ngIf="activeEdit === nStatus.TimeZone">
                           <mat-form-field class="full-width">
                              <mat-select [(ngModel)]="selectedSensor.timezone">
                                 <app-mat-dropdown-search [(ngModel)]="searchTerm"
                                    (ngModelChange)="filterZones($event)"></app-mat-dropdown-search>
                                 <mat-option *ngFor="let timezone of filteredTimeZones" [value]="timezone">
                                    {{ timezone }}
                                 </mat-option>
                              </mat-select>
                           </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="activeEdit !== nStatus.TimeZone">{{
                           selectedSensor.timezone
                        }}</ng-container>
                     </td>
                     <td>
                        <div fxLayout fxLayoutAlign="center">
                           <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                              <mat-icon *ngIf="activeEdit !== nStatus.TimeZone"
                                 (click)="onEdit(nStatus.TimeZone)">edit</mat-icon>
                           </div>
                           <ng-container *ngIf="activeEdit === nStatus.TimeZone">
                              <mat-icon (click)="onSave()" class="pointer mr-1" color="primary">check </mat-icon>

                              <div class="delete-item pointer" fxLayout fxLayoutAlign="center center">
                                 <mat-icon (click)="onEdit(nStatus.Default)">close</mat-icon>
                              </div>
                           </ng-container>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td>Address</td>
                     <td>
                        <ng-container *ngIf="activeEdit === nStatus.Address">
                           <mat-form-field>
                              <input matInput type="text" [(ngModel)]="selectedSensor.address" />
                           </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="activeEdit !== nStatus.Address">{{ selectedSensor.address }}</ng-container>
                     </td>
                     <td>
                        <div fxLayout fxLayoutAlign="center">
                           <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                              <mat-icon *ngIf="activeEdit !== nStatus.Address"
                                 (click)="onEdit(nStatus.Address)">edit</mat-icon>
                           </div>
                           <ng-container *ngIf="activeEdit === nStatus.Address">
                              <mat-icon class="pointer mr-1" color="primary" (click)="onUpdate()">check </mat-icon>

                              <div class="delete-item pointer" fxLayout fxLayoutAlign="center center">
                                 <mat-icon (click)="onEdit(nStatus.Default)">close </mat-icon>
                              </div>
                           </ng-container>
                        </div>
                     </td>
                  </tr>

                  <tr *ngIf="isStaff">
                     <td>Serial Number</td>
                     <td>
                        <ng-container *ngIf="activeEdit === nStatus.SerialNumber">
                           <mat-form-field>
                              <input matInput type="text" [(ngModel)]="selectedSensor.serialNumber" />
                           </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="activeEdit !== nStatus.SerialNumber">{{
                           selectedSensor.serialNumber
                        }}</ng-container>
                     </td>
                     <td>
                        <div fxLayout fxLayoutAlign="center">
                           <div class="edit-item pointer" fxLayout fxLayoutAlign="center center">
                              <mat-icon *ngIf="activeEdit !== nStatus.SerialNumber"
                                 (click)="onEdit(nStatus.SerialNumber)">edit</mat-icon>
                           </div>
                           <ng-container *ngIf="activeEdit === nStatus.SerialNumber">
                              <mat-icon class="pointer mr-1" color="primary" (click)="onSave()">check </mat-icon>

                              <div class="delete-item pointer" fxLayout fxLayoutAlign="center center">
                                 <mat-icon (click)="onEdit(nStatus.Default)">close </mat-icon>
                              </div>
                           </ng-container>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined">
         <mat-card-content class="content container-fluid">
            <h5>Weather</h5>
            <weather-live [strUserType]="strUserType" [strUserRole]="strUserRole" [sensor]="selectedSensor"
               (updateWeather)="onUpdateWeather($event)" (updateModemSensor)="updateModemSensorId($event)"
               (updateGeolocation)="updateGeolocationSensor($event)"
               (updateWeatherSensor)="updateWeatherSensorId($event)" (updateTimezone)="getTimezone($event)">
            </weather-live>
         </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined" *ngIf="isStaff">
         <mat-card-content class="content container-fluid">
            <h5>Cleaning</h5>
            <clean-cycle [strUserType]="strUserType" [strUserRole]="strUserRole" [sensor]="selectedSensor"
               (updateCleaningCycle)="updateCleaningCycle($event)"></clean-cycle>
         </mat-card-content>
      </mat-card>
   </div>
   <div class="card-right">
      <!--              FAST MODE -->

      <mat-card appearance="outlined" *ngIf="isStaff">
         <ng-container>
            <h5>Omniscent Admin Only Settings</h5>
            <table class="table table-bordered table-striped fast-mode-sensor">
               <thead>
                  <tr>
                     <th>Title</th>
                     <th>Value</th>
                  </tr>
               </thead>
               <tbody>
                  <ng-container>
                     <tr>
                        <td>
                           <mat-form-field class="full-width mr-4">
                              <mat-label>Enter name for Fast Mode configuration 1</mat-label>
                              <input required placeholder="Enter name for Fast Mode Configuration 1" matInput
                                 type="text" [ngModelOptions]="{ updateOn: 'blur' }"
                                 [(ngModel)]="selectedSensor['fastModeConfigName1']"
                                 (ngModelChange)="onChangeDualModeLabel($event, 'one')"
                                 [disabled]="nActionStatus === 2 || nActionStatus === 12" />
                           </mat-form-field>
                        </td>
                        <td *ngIf="arrConfigs.length">
                           <mat-form-field class="full-width">
                              <mat-label>Select configuration file</mat-label>
                              <mat-select placeholder="Select configuration file" class="full-width"
                                 (ngModelChange)="onChangeOneMinuteSamplingFile($event)"
                                 [(ngModel)]="oneMinuteSamplingFile"
                                 [disabled]="nActionStatus === 2 || nActionStatus === 12">
                                 <mat-form-field class="search-input">
                                    <input matInput placeholder="Search..." [(ngModel)]="fileOne"
                                       (ngModelChange)="onSearchElement($event, 'one')" />
                                 </mat-form-field>
                                 <mat-option *ngFor="let configType of filteredConfigsOne" [value]="configType['key']">
                                    {{ configType['name'] }}
                                 </mat-option>
                              </mat-select>
                           </mat-form-field>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <mat-form-field class="full-width mr-4">
                              <mat-label>Enter name for Fast Mode Configuration 2</mat-label>
                              <input required placeholder="Enter name for Fast Mode Configuration 2" matInput
                                 type="text" [ngModelOptions]="{ updateOn: 'blur' }"
                                 [(ngModel)]="selectedSensor['fastModeConfigName2']"
                                 (ngModelChange)="onChangeDualModeLabel($event, 'two')"
                                 [disabled]="nActionStatus === 2 || nActionStatus === 12" />
                           </mat-form-field>
                        </td>
                        <td>
                           <mat-form-field class="full-width">
                              <mat-label>Select configuration file</mat-label>
                              <mat-select placeholder="Select configuration file" class="full-width"
                                 (ngModelChange)="onChangeTwoMinutesSamplingFile($event)"
                                 [(ngModel)]="twoMinuteSamplingFile"
                                 [disabled]="nActionStatus === 2 || nActionStatus === 12">
                                 <mat-form-field class="search-input">
                                    <input matInput placeholder="Search..." [(ngModel)]="fileTwo"
                                       (ngModelChange)="onSearchElement($event, 'two')" />
                                 </mat-form-field>
                                 <mat-option *ngFor="let configType of filteredConfigsTwo" [value]="configType['key']">
                                    {{ configType['name'] }}
                                 </mat-option>
                              </mat-select>
                           </mat-form-field>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <mat-form-field class="full-width mr-4">
                              <mat-label>Enter name for Normal Mode configuration</mat-label>
                              <input required placeholder="Enter name for Normal Mode configuration" matInput
                                 type="text" [ngModelOptions]="{ updateOn: 'blur' }"
                                 [(ngModel)]="selectedSensor['normalModeConfigName']"
                                 (ngModelChange)="onChangeDualModeLabel($event, 'normal')"
                                 [disabled]="nActionStatus === 2 || nActionStatus === 12" />
                           </mat-form-field>
                        </td>
                        <td>
                           <mat-form-field class="full-width">
                              <mat-label>Select normal mode sampling file</mat-label>
                              <mat-select placeholder="Select normal mode sampling file" class="full-width"
                                 (ngModelChange)="onChangeNormalModeSamplingFile($event)"
                                 [(ngModel)]="normalModeSamplingFile"
                                 [disabled]="nActionStatus === 2 || nActionStatus === 12">
                                 <mat-form-field class="search-input">
                                    <input matInput placeholder="Search..." [(ngModel)]="fileThree"
                                       (ngModelChange)="onSearchElement($event, 'normal')" />
                                 </mat-form-field>
                                 <mat-option *ngFor="let configType of filteredNormalMode" [value]="configType['key']">
                                    {{ configType['name'] }}
                                 </mat-option>
                              </mat-select>
                           </mat-form-field>
                        </td>
                     </tr>
                     <tr *ngIf="selectedSensor.sensorTypeId != ''">
                        <td>
                           <mat-label>Sensor Model</mat-label>
                        </td>
                        <td>
                           <mat-form-field class="full-width">
                              <mat-label>Sensor Model</mat-label>
                              <mat-select placeholder="Select Sensor Model" class="full-width" matInput type="text"
                                 [ngModelOptions]="{ updateOn: 'blur' }" (ngModelChange)="onChangeSensorModel($event)"
                                 [(ngModel)]="selectedSensor.sensorModel"
                                 [disabled]="nActionStatus === 2 || nActionStatus === 12">
                                 <mat-form-field class="search-input">
                                    <input matInput placeholder="Search..." [(ngModel)]="senModelinput"
                                       (ngModelChange)="onSearchElement($event, 'senModel')" />
                                 </mat-form-field>
                                 <mat-option value="null">custom</mat-option>
                                 <mat-option *ngFor="let model of filteredsenModel" [value]="model['key']">
                                    {{ model['modelName'] }}
                                 </mat-option>
                              </mat-select>
                           </mat-form-field>
                        </td>
                     </tr>
                  </ng-container>
               </tbody>

            </table>
         </ng-container>
      </mat-card>
      <mat-card appearance="outlined" *ngIf="isStaff">
         <!-- <ng-container> -->
         <h5>Omniscent Admin Only Calibration Settings</h5>
         <table class="table table-bordered table-striped fast-mode-sensor">
            <thead>
               <tr>
                  <th>Title</th>
                  <th>Value</th>
               </tr>
            </thead>
            <tbody>
               <ng-container>
                  <tr>
                     <td>
                        <mat-label>Fast Mode Calibration</mat-label>
                     </td>
                     <td>
                        <mat-form-field class="full-width">
                           <mat-label>Select calibration file</mat-label>
                           <!-- Display currently selected file name -->

                           <mat-select placeholder="Select configuration file" class="full-width"
                              (ngModelChange)="onAssignSamplingTimeForCalibrationForFast($event)"
                              [(ngModel)]="samplingFileForCalForFastMode"
                              [disabled]="nActionStatus === 2 || nActionStatus === 12">
                              <mat-form-field class="search-input">
                                 <input matInput placeholder="Search..." [(ngModel)]="fileFour"
                                    (ngModelChange)="onSearchElement($event, 'fastCal')" />
                              </mat-form-field>

                              <mat-option *ngFor="let configType of filteredFastModeCal" [value]="configType['key']">
                                 {{ configType['name'] }}
                              </mat-option>
                           </mat-select>
                        </mat-form-field>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <mat-label>Normal Mode Calibration</mat-label>
                     </td>
                     <td>
                        <mat-form-field class="full-width">
                           <mat-label>Select calibration file</mat-label>
                           <mat-select placeholder="Select calibration file" class="full-width"
                              (ngModelChange)="onAssignSamplingTimeForCalibration($event)"
                              [(ngModel)]="samplingFileForCal" [disabled]="nActionStatus === 2 || nActionStatus === 12">
                              <mat-form-field class="search-input">
                                 <input matInput placeholder="Search..." [(ngModel)]="fileFive"
                                    (ngModelChange)="onSearchElement($event, 'normalCal')" />
                              </mat-form-field>

                              <mat-option *ngFor="let configType of filteredNormalModeCal" [value]="configType['key']">
                                 {{ configType['name'] }}
                              </mat-option>
                           </mat-select>
                        </mat-form-field>
                     </td>
                  </tr>
               </ng-container>
            </tbody>
         </table>
         <!-- </ng-container> -->
      </mat-card>
      <!-- Fast Mode Settings -->

      <ng-container>
         <mat-card>
            <h5>Operation Mode Settings</h5>
            <table class="table table-bordered table-striped fast-mode-sensor">
               <thead>
                  <tr>
                     <th>Title</th>
                     <th>Value</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>Operation Mode</td>
                     <td>
                        <mat-slide-toggle (change)="onChangeDualMode($event)" color="primary"
                           [ngModel]="selectedSensor['fastMode']" [disabled]="enableDisblemodeButton">
                           <b [ngStyle]="selectedSensor['fastMode'] ? { color: 'green' } : { color: 'green' }">{{
                              selectedSensor['fastMode'] ? 'Fast Mode' : 'Normal Mode'
                           }}</b>
                        </mat-slide-toggle>
                     </td>
                  </tr>
                  <tr>
                     <td>Current Configuration</td>
                     <td>
                        <mat-form-field class="full-width">
                           <mat-label>Select file</mat-label>
                           <mat-select placeholder="Select file" class="full-width"
                              (ngModelChange)="onAssignSamplingTime($event)" [(ngModel)]="currentSamplingFileBinding"
                              [disabled]="nActionStatus === 2 || nActionStatus === 12">
                              <mat-option *ngFor="
                                    let timeKey of selectedSensor['fastMode']
                                       ? arrSamplingTimesFast
                                       : arrSamplingTimesNormal
                                 " [value]="timeKey['key']">
                                 {{ timeKey['name'] }}
                              </mat-option>
                           </mat-select>
                        </mat-form-field>
                     </td>
                  </tr>
                  <tr>
                     <td>Max Runs</td>
                     <td>
                        <mat-form-field class="full-width">
                           <mat-label>Max Runs</mat-label>
                           <input matInput type="number" placeholder="Max Runs" [(ngModel)]="selectedSensor['maxRuns']"
                              min="1" [max]="9999" step="1" (change)="onChangeMaxRuns($event)"
                              [disabled]="nActionStatus === 2 || nActionStatus === 12" />
                        </mat-form-field>
                     </td>
                  </tr>
                  <tr>
                     <td>Reporting Mode</td>
                     <td>
                        <mat-slide-toggle color="primary" [(ngModel)]="isIntervalMode" (change)="onModeChange($event)"
                           [disabled]="nActionStatus === 2 || nActionStatus === 12 || selectedSensor.fastMode">
                           {{ isIntervalMode ? 'Interval' : 'Continuous' }}
                        </mat-slide-toggle>
                     </td>
                  </tr>
                  <tr *ngIf="isIntervalMode">
                     <td>Interval Options</td>
                     <td>
                        <mat-form-field appearance="fill" class="full-width">
                           <mat-label>Select Interval</mat-label>
                           <mat-select [(ngModel)]="selectedInterval" (ngModelChange)="onIntervalChange($event)"
                              [disabled]="selectedSensor.fastMode">
                              <mat-option value="30">30 Minutes</mat-option>
                              <mat-option value="60">1 Hour</mat-option>
                           </mat-select>
                        </mat-form-field>
                     </td>
                  </tr>

               </tbody>
            </table>
         </mat-card>
      </ng-container>
   </div>
</div>